import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Section = ({ data }) => (
  <section id="konzept" className="page-section">
      <h2 className="text-center section-heading text-uppercase">Konzept</h2>
      <article dangerouslySetInnerHTML={{ __html: data.html }} />
  </section>
);

export default function Konzept(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
            allMarkdownRemark(filter: { frontmatter:  { templateId: { eq:"konzept"}}}){
             edges{
              node{
                html,
                frontmatter {
                  title
                }
              }
            }
            }
        }
      `}
      render={(data) => {
        const page = data.allMarkdownRemark.edges[0].node;
        return (<Section data={page} {...props} />)
      }}
    />
  )
}