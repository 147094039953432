import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Team from './team';
import Kontakt from './kontakt'
import Konzept from './konzept'

class IndexPage extends React.Component {
  render() {
    const pages = this.props.data.allMarkdownRemark.edges.map(({ node }) => ({
      html: node.html,
      title: node.frontmatter.title,
      id: node.frontmatter.templateId
    }))

    return (<Layout>
      <SEO title="Home" />
      <section className="page-section" id="services">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Leistungen</h2>
            <p className="section-subheading text-muted">Handwerksbetriebe, Bauunternehmen, Immobilien</p>
            <br />
          </div>
          <div className="container">
            <div className="row text-center">
              <div className="col-md-4">
                <a className="icon-animated fa-stack fa-4x" data-toggle="modal" href="#jahresabschluss">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <img className="fa-stack-1x icon-100px" src="uploads/01_Jahresabschluss.png" />
                </a>
                <h4 className="my-3">Jahresabschluss</h4>
              </div>

              <div className="col-md-4">
                <a className="icon-animated fa-stack fa-4x" data-toggle="modal" href="#steuerberatung">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <img className="fa-stack-1x icon-100px" src="uploads/02_Steuerberatung.png" />
                </a>
                <h4 className="my-3">Steuerberatung</h4>
              </div>
              <div className="col-md-4">
                <a className="icon-animated fa-stack fa-4x" data-toggle="modal" href="#lohnbuchhaltung">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <img className="fa-stack-1x icon-100px" src="uploads/05_Lohnbuchhaltung.png" />
                </a>
                <h4 className="my-3">Lohnbuchhaltung</h4>
              </div>
            </div>
            <div className="row justify-content-center text-center">
              <div className="col-md-4">
                <a className="icon-animated fa-stack fa-4x" data-toggle="modal" href="#steuererklarungen">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <img className="fa-stack-1x icon-100px" src="uploads/07_Steuererklärung.png" />
                </a>
                <h4 className="my-3">Steuererklärungen</h4>
              </div>
              <div className="col-md-4">
              <a className="icon-animated fa-stack fa-4x" data-toggle="modal" href="#finanzbuchhaltung">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <img className="fa-stack-1x icon-100px" src="uploads/04_Finanzbuchhaltung.png" />
                </a>
                <h4 className="my-3">Finanzbuchhaltung</h4>
              </div>
            </div>
          </div>

        </div>
      </section>
      <Konzept></Konzept>
      <Team></Team>
      <Kontakt></Kontakt>
      {pages.map( page => (
        <div key={page.id} className="portfolio-modal modal fade" id={page.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" />
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="modal-body">
                    <article 
                      className="modal-content-article" 
                      dangerouslySetInnerHTML={{ __html: page.html }}>
                    </article>
                    <br/>
                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                      <i className="fas fa-times mr-1"></i>
                        Zurück
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))}
      
    </Layout>)
  }
}

export default IndexPage

export const pageQuery = graphql`
{
  allMarkdownRemark(filter: { frontmatter:  { templateId: { in:[
    "jahresabschluss",  "finanzbuchhaltung", "lohnbuchhaltung", 
    "steuererklarungen", "steuerberatung", "karriere", 
    "interessante_links", "impressum", "mandatenbrief","aabs"]}}}){
   edges{
    node{
      html,
      frontmatter {
        title,
        templateId
      }
    }
  }
  }
}
`;
// query PageBySlug($slug: String! = "services-de") {
//   markdownRemark(frontmatter: { templateId: { eq: $slug } }) {
//     id
//     html
//     frontmatter {
//       description,
//       title,
//     }
//   }
// }

