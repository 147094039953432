import React from "react"

class Kontakt extends React.Component {
  render() {
    return (
      <>
        <section className="page-section" id="contact">
          <div className="container">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">Sie finden uns hier:</h2>
              <section className="nolle"></section>
              <div className="row">
                <div className="col"><a href="mailto:info@steuerberater-kunkel.de">info@steuerberater-kunkel.de</a></div>
              </div>
              <div className="row">
                <div className="col"><a href="https://goo.gl/maps/FtnCbnmsBG2psckH9">Kurfürstenstraße, 10785 Berlin</a></div>
              </div>
              <div className="row">
                <div className="col"><a href="tel:+493021000630">Tel: +49 30 21 00063-0</a></div>
              </div>
              <div className="row">
                <div className="col"><a href="tel:+4930210006333">Fax: +49 30 21 00063-33</a></div>
              </div>
              <br/>
              <div className="row">
                <div className="col"><a href="#">Unsere Bürozeiten:</a></div>
              </div>
              <div className="row">
                <div className="col"><a href="#">Montag - Freitag 8.00 - 12.00 Uhr</a></div>
              </div>
              <div className="row">
                <div className="col"><a href="#">13.00 - 17.00 Uhr (nach Vereinbarung)</a></div>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.7290607011228!2d13.35076221580683!3d52.50214387981091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8504dbd6731f5%3A0x2d00cc8bbe93582f!2sKurf%C3%BCrstenstra%C3%9Fe%20130%2C%2010785%20Berlin!5e0!3m2!1sen!2sde!4v1609858625969!5m2!1sen!2sde"
                width="100%" height="450" frameBorder="0"
                style={({ border: 0, filter: 'invert(90%)' })} allowFullScreen="" aria-hidden="false" tabIndex="0">
              </iframe>
            </div>
          </div>
        </section>
        <section className="page-section">
          <h3 className="section-heading" id="contactFormHeader">Kontaktformular</h3>
          <br />
          <form id="contactForm" name="sentMessage" noValidate="novalidate" action="https://api.web3forms.com/submit" method="POST">
            <input type="hidden" name="redirect" value="https://web3forms.com/success" />
            <input type="hidden" name="access_key" value="43c3eb52-7e20-4fbd-898b-db84c675b737"></input>
            <div className="row align-items-stretch mb-5">
              <div className="col-md-6">
                <div className="form-group">
                  <input className="form-control" id="name" name="name" type="text" placeholder="Vorname *" required="required" data-validation-required-message="Please enter your name." />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <input className="form-control" id="surname" name="surname" type="text" placeholder="Nachname *" required="required" data-validation-required-message="Please enter your email address." />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group mb-md-0">
                  <input className="form-control" id="email" name="email" type="email" placeholder="E-Mail *" required="required" data-validation-required-message="Please enter your phone number." />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group mb-md-0">
                  <input className="form-control" id="phone" name="phone" type="tel" placeholder="Telefon" required="required" data-validation-required-message="Please enter your phone number." />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group form-group-textarea mb-md-0">
                  <textarea className="form-control" id="message" name="message" placeholder="Seine Nachricht" required="required"></textarea>
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="text-center" style={{ marginBottom: '2em'}}>
              <div id="success"></div>
              <button className="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit">
                Nachricht senden
              </button>
            </div>
          </form>
        </section>
      </>
    )
  }
}

export default Kontakt
