import React from "react"

class TeamPage extends React.Component {
  render() {
    return (
      <section className="page-section" id="team">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Unser Team</h2>
                <h3 className="section-subheading text-muted"><a data-toggle="modal" href="#karriere">Bewirb dich noch heute! </a>Wir freuen uns auf dich.</h3>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/the_boss.jpg"
                            alt="" />
                        <h4>Burkhard Kunkel</h4>
                        <p className="text-muted">Diplom-Kaufmann</p>
                        <p className="text-muted">Steuerberater</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/placeholder.jpeg"
                            alt="" />
                        <h4>Gundula Kastaun</h4>
                        <p className="text-muted">Dipl.-Betriebswirtin seit 1981</p>
                        <p className="text-muted">Im Team seit 1999</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/antje.jpg"
                            alt="" />
                        <h4>Antje Beckström</h4>
                        <p className="text-muted">Steuerfachangestellte seit 2001</p>
                        <p className="text-muted">Dipl.-Betriebswirtin (BA) seit 2005</p>
                        <p className="text-muted">Im Team seit 2001</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/anja.jpg"
                            alt="" />
                        <h4>Anja Jonschkowski</h4>
                        <p className="text-muted">Steuerfachangestellte seit 2011</p>
                        <p className="text-muted">Bilanzbuchhalterin seit 2017</p>
                        <p className="text-muted">Im Team seit 2012</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/thomas.jpg"
                            alt="" />
                        <h4>Thomas Horn</h4>
                        <p className="text-muted">Diplom-Kaufmann seit 2007</p>
                        <p className="text-muted">Bilanzbuchhalter seit 2011</p>
                        <p className="text-muted">Im Team seit 2013</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/lea.jpg"
                            alt="" />
                        <h4>Lea Dittrich</h4>
                        <p className="text-muted">Steuerfachangestellte seit 2018</p>
                        <p className="text-muted">Bachelor of Arts (BA)</p>
                        <p className="text-muted">Im Team seit 2015</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/ina.jpg"
                            alt="" />
                        <h4>Ina Schwertfeger</h4>
                        <p className="text-muted">Steuerfachangestellte seit 1994</p>
                        <p className="text-muted">Im Team seit 2018</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/nicole.jpg"
                            alt="" />
                        <h4>Nicole Schmidt</h4>
                        <p className="text-muted">Kauffrau f. Büromanagement seit 2017</p>
                        <p className="text-muted">Im Team seit 2015</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/the_agent.jpeg"
                            alt="" />
                        <h4>Jakob Kunkel</h4>
                        <p className="text-muted">Steuerfachangestellter seit 2021</p>
                        <p className="text-muted">Im Team seit 2019</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/merle.jpg"
                            alt="" />
                        <h4>Merle Rudolph</h4>
                        <p className="text-muted">Steuerfachangestellte seit 2022</p>
                        <p className="text-muted">Im Team seit 2020</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/petra.jpg"
                            alt="" />
                        <h4>Petra Balzer</h4>
                        <p className="text-muted">Lohnsachbearbeiterin seit 2021</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="team-member">
                        <img className="mx-auto rounded-circle"
                            src="/assets/img/team/tugce.jpeg"
                            alt="" />
                        <h4>Tugce Dogan</h4>
                        <p className="text-muted">Auszubildende, im Team seit 2021</p>
                    </div>
                </div>
            </div>
        </div>
    </section>)
  }
}

export default TeamPage